import React from 'react';
import logo from '../images/logo.png';

export default function Header() {
    return (
        <>
            <nav className="flex items-center justify-between flex-wrap bg-gradient-to-r from-rose-50 via-slate-100 to-rose-50  p-6 border-b-[5px] border-b-rose-100">
                <div className="flex items-center flex-shrink-0 text-white mr-6">
                    <img className="h-24 ml-10" src={logo} alt="Logo Carpediem" />
                    <span className="font-bold text-xl tracking-tight text-[#7F3E98] ">Espace Carpediem</span>
                </div>
                <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                    <div className="font-semibold lg:flex-grow mr-10">
                        <a
                            href="/"
                            className="bg-rose-100 block mt-4 py-1 px-3 rounded lg:inline-block lg:mt-0 text-[#E94726] hover:text-[#7F3E98] hover:underline mr-6"
                        >
                            Accueil
                        </a>
                        <a
                            href="/spaces"
                            className="bg-rose-100 block mt-4 py-1 px-3 rounded lg:inline-block lg:mt-0 text-[#E94726] hover:text-[#7F3E98] hover:underline mr-6"
                        >
                            Nos Espaces
                        </a>
                        <a
                            href="/conditions"
                            className="bg-rose-100 block mt-4 py-1 px-3 rounded lg:inline-block lg:mt-0 text-[#E94726] hover:text-[#7F3E98] hover:underline mr-6"
                        >
                            Conditions & Tarifs
                        </a>
                        <a
                            href="/intervenants"
                            className="bg-rose-100 block mt-4 py-1 px-3 rounded lg:inline-block lg:mt-0 text-[#E94726] hover:text-[#7F3E98] hover:underline mr-6"
                        >
                            Intervenants
                        </a>
                        <a
                            href="/info"
                            className="bg-rose-100 block mt-4 py-1 px-3 rounded lg:inline-block lg:mt-0 text-[#E94726] hover:text-[#7F3E98] hover:underline mr-6"
                        >
                            A propos de nous
                        </a>
                        <a
                            href="/contact"
                            className="bg-rose-100 block mt-4 py-1 px-3 rounded lg:inline-block lg:mt-0 text-[#E94726] hover:text-[#7F3E98] hover:underline mr-6"
                        >
                            Contact
                        </a>
                    </div>
                    <div>
                        <a
                            href="https://prendreunrendezvous.fr/rendez-vous/389249/location/julea-sci-espace-carpediem"
                            target="_blank"
                            rel="noreferrer"
                            className="inline-block text-sm px-4 py-2 leading-none border rounded font-bold text-[#7F3E98] border-[#7F3E98] hover:border-transparent hover:bg-neutral-300 hover:bg-white mt-4 py-1 px-3 rounded lg:mt-0"
                        >
                            Prendre un Rendez-Vous
                        </a>
                    </div>
                </div>
            </nav>
        </>
    );
}
