import React from 'react';
import styled from 'styled-components';

import Header from '../components/header_component';
import Footer from '../components/footer_component';

import bgMain from '../images/bgMain.jpg';
import contentImage from '../images/contentImage.jpg';
import attente1 from '../images/attente1.jpeg';
import attente2 from '../images/attente2.jpeg';

const Background = styled.div`
    background-image: url(${bgMain}), linear-gradient(#eb01a5, #d13531);
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

export default function MainPage() {
    return (
        <>
            <Header />
            <main>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen">
                    <div className="absolute top-0 w-full h-full">
                        <Background />
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full lg:w-9/12 px-4 ml-auto mr-auto text-center">
                                <div className="rounded-lg p-2 bg-[#C9BFB0] border-[#626262] border-opacity-75 border-[3px]">
                                    <h1 className="text-rose-50 font-semibold text-5xl">"Espace bien-être"</h1>
                                    <p className="mt-4 text-lg text-rose-50">
                                        Carpediem est un concept de location à la carte, adaptant votre location à
                                        l'exercice de votre activité. <br />
                                        Vous trouverez sur le site dans la rubrique{' '}
                                        <a href="/spaces" className="font-semibold">
                                            "Nos Espaces"{' '}
                                        </a>
                                        une description de nos locaux. <br />
                                        Vous pouvez louer à tout moment un ou plusieurs espaces, à la journée ou à la
                                        demi-journée.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-16">
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon className="text-rose-100 fill-current" points="2560 0 2560 100 0 100"></polygon>
                        </svg>
                    </div>
                </div>

                <section className="pb-20 bg-rose-100 -mt-24">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap">
                            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words rounded-lg p-2 bg-[#E3D8C7] border-[#C9BFB0] border-[3px] w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-[#DF3274]">
                                            <i className="fas fa-award"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Espace Gaïa</h6>
                                        <p className="mt-2 mb-4 text-gray-600">
                                            Cet Espace est dédié à la pratique des thérapies alternatives. Il est
                                            possible d'accueillir un petit groupe.
                                            <br />
                                            (jusqu'à 8 personnes)
                                        </p>
                                        <div>
                                            <a
                                                href="https://www.nathalie-polymeros.fr/"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="inline-block text-sm px-4 py-2 leading-none border rounded font-bold text-[#7F3E98] border-[#7F3E98] mt-4 py-1 px-3 rounded lg:mt-0"
                                            >
                                                Espace occupé par Nathalie POLYMEROS
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words rounded-lg p-2 bg-[#E3D8C7] border-[#C9BFB0] border-[3px] w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-[#7F3E98]">
                                            <i className="fas fa-retweet"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Espace Les Pléïades</h6>
                                        <p className="mt-2 mb-4 text-gray-600">
                                            Cet Espace est dédié à la pratique des thérapies alternatives. Il est
                                            possible d'accueillir un petit groupe.
                                            <br />
                                            (jusqu'à 15 personnes)
                                        </p>
                                        <div>
                                            <a
                                                href="https://prendreunrendezvous.fr/rendez-vous/389249/location/julea-sci-espace-carpediem"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="inline-block text-sm px-4 py-2 leading-none border rounded font-bold text-[#C2B8AA] border-[#C2B8AA] hover:border-transparent hover:bg-[#7F3E98] mt-4 py-1 px-3 rounded lg:mt-0"
                                            >
                                                Réserver
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words rounded-lg p-2 bg-[#E3D8C7] border-[#C9BFB0] border-[3px] w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-[#E94726]">
                                            <i className="fas fa-fingerprint"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Espace Nikola Tesla</h6>
                                        <p className="mt-2 mb-4 text-gray-600">
                                            Cet espace est dédié à l'exercice de conférences, interventions et
                                            formations. Il permet l'accueil des groupes. Il dispose d'une caféteria et
                                            d'un espace détente pour les pauses.
                                        </p>
                                        <div>
                                            <a
                                                href="https://prendreunrendezvous.fr/rendez-vous/389249/location/julea-sci-espace-carpediem"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="inline-block text-sm px-4 py-2 leading-none border rounded font-bold text-[#C2B8AA] border-[#C2B8AA] hover:border-transparent hover:bg-[#7F3E98] mt-4 py-1 px-3 rounded lg:mt-0"
                                            >
                                                Réserver
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full md:w-4/12 px-4 text-center"></div>

                            <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                                <div className="relative flex flex-col min-w-0 break-words rounded-lg p-2 bg-[#E3D8C7] border-[#C9BFB0] border-[3px] w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-[#EC7A21]">
                                            <i className="fas fa-fingerprint"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Neuro Hypnose Process</h6>
                                        <p className="mt-2 mb-4 text-gray-600">Hypnologue et maître praticienne PNL</p>
                                        <div>
                                            <a
                                                href="https://www.hypnose-savoie.fr/"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="inline-block text-sm px-4 py-2 leading-none border rounded font-bold text-[#7F3E98] border-[#7F3E98] mt-4 py-1 px-3 rounded lg:mt-0"
                                            >
                                                Espace occupé par Sabrina PARENT
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-wrap items-center mt-32">
                            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-[#EC7A21]">
                                    <i className="fas fa-user-friends text-xl"></i>
                                </div>
                                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                    Un lieu de partage et de convivialité
                                </h3>
                                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                                    Ces espaces ont pour but de vous permetre d'exercer en toute liberté d'esprit.
                                </p>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
                                    Grâce à cette location à la carte vous pouvez prévoir vos rendez-vous et accueillir
                                    vos patient / clients en fonction de la disponibilité des espaces et vice-versa.{' '}
                                    <br />
                                    <br />
                                    Du matériel professionnel est mis à vtre disposition pour vous éviter toute
                                    contrainte éventuelle.
                                </p>
                            </div>

                            <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-600">
                                    <img alt="" src={contentImage} className="w-full align-middle rounded-t-lg" />
                                    <blockquote className="relative p-8 mb-4">
                                        <svg
                                            preserveAspectRatio="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 583 95"
                                            className="absolute left-0 w-full block"
                                            style={{
                                                height: '95px',
                                                top: '-94px',
                                            }}
                                        >
                                            <polygon
                                                points="-30,95 583,95 583,65"
                                                className="text-[#7F3E98] fill-current"
                                            ></polygon>
                                        </svg>
                                        <span className="font-bold text-gray-800">
                                            Avec Carpediem, venez exercer sereinement !
                                        </span>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="relative py-20">
                    <div
                        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
                        style={{ height: '80px' }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon className="text-white fill-current" points="2560 0 2560 100 0 100"></polygon>
                        </svg>
                    </div>

                    <div className="container mx-auto px-4">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                                <img alt="..." className="max-w-full rounded-lg shadow-lg" src={attente1} />
                            </div>
                            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                                <div className="md:pr-12">
                                    <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-[#E94726]">
                                        <i className="fas fa-rocket text-xl"></i>
                                    </div>
                                    <h3 className="text-3xl font-semibold">Un espace d'attente</h3>
                                    <p className="mt-4 text-lg leading-relaxed text-gray-600">
                                        Voici un aperçu de la salle d'attente mise à disposition pour vos patients /
                                        clients.
                                    </p>
                                    <div className="mt-6">
                                        <div className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <img
                                                        alt="..."
                                                        className="max-w-full rounded-lg shadow-lg"
                                                        src={attente2}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pb-20 bg-rose-100 -mt-24"></section>
            </main>
            <Footer />
        </>
    );
}
