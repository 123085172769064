import React from 'react';

import Header from '../components/header_component';
import Footer from '../components/footer_component';

export default function ConfidentialPoliticPage() {
    const handleClickScroll = () => {
        const element = document.getElementById('contact');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <Header />
            <main className="min-h-screen">
                <div className="text-left pl-32 py-5">
                    <h1 className="font-semibold underline">POLITIQUES DE CONFIDENTIALITÉS</h1>
                    <br />
                    <h2 className="italic">Version du 1er Mars 2020</h2> <br />
                    <p>
                        Nous attachons la plus haute importance à la protection de votre vie privée. C’est pourquoi,
                        nous nous engageons à respecter la confidentialité des données personnelles que nous collectons.
                        La présente politique de confidentialité vous informe de la manière dont ce site utilise et
                        protège les informations que vous nous transmettez lorsque vous naviguez dans ses pages.
                    </p>
                    <br />
                    <p>
                        Veuillez noter que la Politique est susceptible d’être modifiée ou complétée à tout moment par
                        l’Editeur du Site, notamment en vue de se conformer à toute évolution législative,
                        règlementaire, jurisprudentielle ou technologique. Dans un tel cas, la date de sa mise à jour
                        sera clairement identifiée en tête de la présente politique. Ces modifications engagent
                        l’Utilisateur dès leur mise en ligne. Il convient par conséquent que l’Utilisateur consulte
                        régulièrement la présente politique de confidentialité et d’utilisation des cookies afin de
                        prendre connaissance de ses éventuelles modifications.
                    </p>
                    <br />
                    <h2 className="text-2xl">1) Définitions Site : </h2>
                    <br />
                    <p>
                        Concerne le site <a href="https://espace-carpediem.fr">CARPEDIEM.</a>
                        <br />
                        Utilisateur : en tant que visiteur du Site vous devenez utilisateur des contenus et services
                        proposés par le site. <br />
                        Terminal : il s’agit de l’appareil que vous utilisez pour accéder et naviguer sur le Site
                        (ordinateur, tablette, smartphone…).
                    </p>
                    <br />
                    <h2 className="text-2xl">2) Données personnelles collectées : </h2>
                    <br />
                    <p>Nous sommes susceptibles de recueillir et de traiter les données suivantes : </p>
                    <br />
                    <p>Les informations que vous nous transmettez directement : </p>
                    <br />
                    <p>
                        En naviguant sur le site, vous pouvez être amené à nous transmettre des informations, dont
                        certaines sont de nature à vous identifier (vos « Données personnelles »). C’est le cas
                        notamment lorsque vous utilisez un formulaire de contact, publiez un commentaire, participez à
                        un sondage, une étude, un jeu-concours, ou vous inscrivez à la newsletter…
                    </p>
                    <br />
                    <p>Ces informations peuvent notamment contenir les Données personnelles suivantes : </p>
                    <br />
                    <ul className="ml-5 list-disc">
                        <li>nom et prénom,</li>
                        <li>adresse e-mail,</li>
                        <li>téléphone,</li>
                        <li>adresse postale,</li>
                        <li>
                            et les autres informations que vous nous transmettez le cas échéant dans le cadre d’un
                            échange ou d’un contact.
                        </li>
                    </ul>
                    <br />
                    <p className="italic">
                        Les informations que nous recueillons automatiquement par le biais de l’interactivité avec le
                        Site :
                    </p>
                    <br />
                    <p>
                        Il s’agit des données recueillies à partir de cookies, notamment par Google Analytics, avec par
                        exemple les informations suivantes :
                    </p>
                    <br />
                    <ul className="ml-5 list-disc">
                        <li>
                            informations relatives aux appareils que vous utilisez pour accéder au Site (ordinateur,
                            tablette, smartphone),
                        </li>
                        <li>adresse IP,</li>
                        <li>
                            données de connexion et de localisation (IMPORTANT : nous avons mis en place un dispositif
                            d’anonymisation des adresses IP ainsi recueillies par Google Analytics),
                        </li>
                        <li>système et plateformes d’exploitation,</li>
                        <li>
                            données concernant votre parcours de navigation sur le site (url des pages visitées, durée
                            de consultation, termes de recherche utilisés, etc…).
                        </li>
                    </ul>
                    <br />
                    <p>
                        Dans le cas où vous utilisez les fonctionnalités de réseaux sociaux mises à votre disposition,
                        nous aurons accès à certaines des données (notamment, vos prénom, nom de famille, photographie,
                        adresse e-mail et nombre d’amis) de votre compte sur ledit réseau social conformément aux
                        conditions générales d’utilisation du réseau social concerné.
                    </p>
                    <br />
                    <p>
                        Nous sommes également susceptibles de recueillir certaines de vos Données personnelles lorsque
                        vous interagissez avec des fonctionnalités de ces réseaux sociaux, tel que les fonctionnalités «
                        J’aime ».
                    </p>
                    <br />
                    <h2 className="text-2xl">3) Cookies :</h2>
                    <br />
                    <p>
                        Les cookies sont des petits fichiers de données texte au format alphanumérique qui sont déposés
                        sur votre disque dur lors de votre navigation sur un site internet. Ces fichiers sont générés
                        par le serveur du site visité ou par un serveur tiers (service de web analytique, régie
                        publicitaire, etc…).
                    </p>
                    <br />
                    <p>
                        Les cookies permettent de vous identifier (à travers votre ordinateur), lors de votre retour sur
                        le site initialement visité. Les cookies ne peuvent pas récupérer ni transmettre aucune donnée
                        de votre disque dur, ni vous transmettre de virus. Le site peut utiliser des cookies et
                        technologies similaires pour faciliter votre navigation en mémorisant vos préférences
                        utilisateur comme pour comprendre l’utilisation que vous faites des services et contenus
                        proposés par le Site, ceci afin de chercher à en améliorer la pertinence et l’ergonomie,
                        notamment.
                    </p>
                    <br />
                    <p>
                        Nous pouvons également inclure dans nos courriers électroniques et newsletters des pixels de
                        traçage, qui sont de petits fichiers images permettant de déterminer si les messages ont été
                        ouverts et si les liens qui y sont inclus ont été consultés.
                    </p>
                    <br />
                    <p>Les cookies et technologies similaires que nous utilisons peuvent être : </p>
                    <br />
                    <ul className="ml-5 list-disc">
                        <li>
                            fonctionnels : c’est-à-dire nécessaires à la réalisation d’une fonctionnalité, telle que
                            votre identification pour une correspondance, un commentaire, ou le cas échéant une commande
                            en ligne, par exemple ;
                        </li>
                        <li>
                            analytiques et de performance : c’est-à-dire liés au suivi et à l’analyse marketing de la
                            fréquentation du Site ;
                        </li>
                        <li>
                            publicitaires et de ciblage : en cas de recours à la publicité, ces cookies peuvent
                            enregistrer vos visites et votre navigation sur les pages du Site ou encore votre
                            localisation, de manière à permettre au Site et à ses partenaires publicitaires de vous
                            proposer des publicités en cohérence avec vos centres d’intérêts et/ou votre situation
                            géographique ;
                        </li>
                        <li>
                            de réseaux sociaux : il s’agit des cookies déposés par les boutons de partage des réseaux
                            sociaux.
                        </li>
                    </ul>
                    <br />
                    <p>
                        Ces cookies peuvent provenir du serveur du site ou de tiers (comme Google Analytics par
                        exemple).
                    </p>
                    <br />
                    <p>
                        Lors de votre navigation initiale sur le Site, il vous est demandé votre consentement à cette
                        utilisation de cookies et vous pouvez à tout moment révoquer votre consentement, si vous le
                        souhaitez, de la manière suivante :
                    </p>
                    <br />
                    <p className="italic">En paramétrant votre navigateur internet : </p>
                    <br />
                    <ul className="ml-5 list-disc">
                        <li>
                            <a
                                className="text-blue-500"
                                href="https://support.google.com/accounts/answer/61416?hl=fr"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Chrome
                            </a>
                        </li>
                        <li>
                            <a
                                className="text-blue-500"
                                href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Safari
                            </a>
                        </li>
                        <li>
                            <a
                                className="text-blue-500"
                                href="https://support.mozilla.org/fr/kb/protection-renforcee-contre-pistage-firefox-ordinateur?redirectlocale=fr&redirectslug=activer-desactiver-cookies-preferences"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Firefox
                            </a>
                        </li>
                        <li>
                            <a
                                className="text-blue-500"
                                href="https://support.microsoft.com/fr-fr/windows/supprimer-et-gérer-les-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Internet Explorer{' '}
                            </a>
                            ou{' '}
                            <a
                                className="text-blue-500"
                                href="https://support.microsoft.com/fr-fr/windows/microsoft-edge-données-de-navigation-et-confidentialité-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Edge
                            </a>
                        </li>
                    </ul>
                    <br />
                    <p className="italic">En désactivant les services de cookies en ligne :</p>
                    <br />
                    <ul className="ml-5 list-disc">
                        <li>Cookies analytiques et de performance : Google Analytics</li>
                        <li>
                            Cookies de réseaux sociaux : Facebook – Twitter – Google+ – YouTube – LinkedIn – Instagram{' '}
                        </li>
                    </ul>
                    <br />
                    <p>
                        Si vous refusez l’enregistrement de cookies sur votreTerminal, vous pouvez continuer à naviguer
                        sur le Site, mais certaines parties ou fonctionnalités pourront ne pas fonctionner correctement.
                    </p>
                    <br />
                    <h2 className="text-2xl">4) Finalité des recueils et traitements de vos Données personnelles :</h2>
                    <p>Nous utilisons vos Données personnelles, avec votre consentement, pour : </p>
                    <br />
                    <ul className="ml-5 list-disc">
                        <li>vous fournir les contenus que vous avez demandés (notamment la newsletter etc…),</li>
                        <li>
                            établir un contact et/ou une correspondance avec vous afin de vous fournir des
                            renseignements sur nos activités et/ou services,
                        </li>
                        <li>
                            vous permettre, le cas échéant, de communiquer avec les autres visiteurs du Site, notamment
                            à travers les commentaires d’articles,
                        </li>
                        <li>
                            disposer de statistiques de fréquentation pour nous permettre d’améliorer la gestion du Site
                            (présentation, organisation, adaptation des contenus à votre appareil, etc…) et votre
                            expérience utilisateur,
                        </li>
                        <li>
                            vous permettre de personnaliser votre profil afin de vous proposer des contenus et des
                            offres en rapport avec vos attentes et centres d’intérêts,
                        </li>
                        <li>
                            vous fournir, le cas échéant, les produits ou services que vous avez pu commander sur le
                            site,
                        </li>
                        <li>
                            percevoir, le cas échéant, des paiements en contrepartie de produits ou services fournis,
                        </li>
                        <li>
                            nous assurer du respect de nos conditions générales d’utilisation du Site, de notre
                            Politique de confidentialité et de la réglementation applicable.
                        </li>
                    </ul>
                    <br />
                    <p>
                        Ces traitements sont fondés sur votre consentement et/ou notre intérêt légitime à mesurer
                        l’efficacité de nos services et le respect de nos obligations respectives.
                    </p>
                    <br />
                    <h2 className="text-2xl">5) Destinataires des informations recueillies</h2>
                    <br />
                    <p>
                        L’activité de l’Editeur n’est pas de faire le commerce de vos Données personnelles. En
                        conséquence, vos Données personnelles ne sont partagées que dans les cas suivants :
                    </p>
                    <br />
                    <ul className="ml-5 list-disc">
                        <li>avec nos prestataires de web analytics (Google Analytics) ;</li>
                        <li>avec nos prestataires de courrierélectronique (MailChimp) ;</li>
                        <li>
                            avec nos prestataires de gestion de fichiers d’abonnés et/ou de clients (comme Zoho CRM le
                            cas échéant) ;
                        </li>
                        <li>avec nos prestataires de paiement (comme PayPal, Payplug ou Stripe, le cas échéant) ;</li>
                        <li>
                            avec nos fournisseurs de produits ou services commercialisés via le Site (le cas échéant) et
                            de la logistique correspondante ;
                        </li>
                        <li>
                            dans le cadre d’obligations légales ou pour faire respecter nos conditions d’utilisation du
                            Site et, d’une manière générale, pour protéger nos droits ;
                        </li>
                        <li>
                            et lorsque vous en faites la demande, comme par exemple, lorsque le cas échéant, vous
                            utilisez une méthode d’authentification fournie par un réseau social ou encore si vous
                            arrivez sur le Site via un clic sur une annonce ou un lien sur un site partenaire.
                        </li>
                    </ul>
                    <br />
                    <h2 className="text-2xl">6) Durée de conservation de vos Données personnelles</h2>
                    <br />
                    <p>
                        Vos Données personnelles sont conservées par l’Editeur et/ou ses prestataires durant le temps
                        nécessaire pour fournir les services correspondant au recueil de ces données ou pour répondre à
                        votre demande.
                    </p>
                    <br />
                    <h2 className="text-2xl">7) Protection de vos Données personnelles </h2>
                    <br />
                    <p>
                        Nous attachons la plus grande importance à la sécurisation du Site et de vos Données
                        personnelles. Celles-ci sont conservées sur notre serveur chez notre prestataire d’hébergement
                        dans un environnement sécurisé, en conformité avec le réglementation RGPD.
                    </p>
                    <br />
                    <p>Notre dispositif de sécurité comprend notamment les éléments suivants : </p>
                    <br />
                    <ul className="ml-5 list-disc">
                        <li>
                            Protection du Site par certificat Let’s Encrypt sous système SSL (Secure Sockets Layer).
                        </li>
                        <li>Pare-feu et surveillance des malware par iThemes Security de iThemes.</li>
                        <li>Encryptage spécifique des adresses e-mail par Email Encoder Bundle.</li>
                        <li>
                            Anonymisation de votre adresse IP vis-à-vis des traitements Google Analytics grâce à Google
                            Analytics Germanized.
                        </li>
                        <li>Accès administrateurs protégés par mots de passe complexes.</li>
                    </ul>
                    <br />
                    <p>
                        De votre côté, vous êtes responsable de la protection de vos identifiant et mot de passe et
                        devez vous prémunir contre un accès non autorisé à ceux-ci ; notamment, si vous partagez un
                        Terminal, vous devez vous assurer de votre parfaite déconnexion après chaque utilisation.
                    </p>
                    <br />
                    <h2 className="text-2xl">8) Vos droits relatifs à vos Données personnelles </h2>
                    <br />
                    <p>Vous disposez des droits suivants vis-à-vis de vos Données personnelles : </p>
                    <br />
                    <ul className="ml-5 list-disc">
                        <li>
                            droit d’accès : vous pouvez accéder aux Données personnelles que nous pouvons détenir sur
                            vous.
                        </li>
                        <li>
                            droit de rectification : vous pouvez mettre à jour vos Données le cas échéant dans
                            l’interface de gestion de vos paramètres ou nous demander de procéder à cette mise à jour.
                        </li>
                        <li>
                            droit d’effacement : vous pouvez nous demander la suppression de vos Données personnelles.
                        </li>
                        <li>
                            droit à la portabilité : vous pouvez nous demander une restitution des Données personnelles
                            que vous nous avez fournies sous forme d’un fichier lisible par une machine.
                        </li>
                    </ul>
                    <br />
                    <p>
                        Pour exercer l’un ou l’autre de ces droits,
                        <span onClick={handleClickScroll} className="font-bold hover:underline">
                            utilisez le bouton de contact au dernier paragraphe ci-dessous.
                        </span>{' '}
                        Nous ferons nos meilleurs efforts pour vous fournir ces informations ou procéder aux
                        modifications ou effacement dans un délai de 30 jours suivant la réception de votre demande,
                        accompagnée en raison de notre obligation de sécurité et de confidentialité de la preuve de
                        votre identité, et sauf demandes manifestement abusives de par leur nombre ou leur caractère
                        répétitif ou systématique.
                    </p>
                    <br />
                    <p>
                        Nous sommes toutefois susceptibles de conserver certaines de vos Données personnelles lorsque la
                        Loi l’impose ou pour un motif légitime, et notamment pour faire valoir nos droits en cas de
                        litige.
                    </p>
                    <br />
                    <h2 className="text-2xl">9) Réglementation applicable </h2>
                    <br />
                    <p>
                        Le site est soumis à la Loi française et notamment au Règlement Général sur la Protection des
                        Données (General Data Protection Regulation) adopté par le Parlement européen et le Conseil le
                        27 avril 2016, et à la Loi Informatique et Libertés du 6 janvier 1978 modifiée. Pour tout
                        renseignement concernant la réglementation en vigueur, vous pouvez consultez le site de la CNIL.
                    </p>
                    <br />
                    <h2 id="contact" className="text-2xl">
                        10) Contact
                    </h2>
                    <br />
                    Pour tout renseignement ou pour toute démarche…{' '}
                    <a className="font-semibold text-blue-500 hover:underline" href="/contact">
                        Contactez-nous
                    </a>
                </div>
            </main>
            <Footer />
        </>
    );
}
