import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import MainPage from './pages/mainPage';
import InfoPage from './pages/infoPage';
import ContactPage from './pages/contactPage';
import NotFound from './pages/notFoundPage';
import SpacesPage from './pages/spacesPage';
import ConditionPage from './pages/conditionPage';
import GuestPage from './pages/guestPage';
import ConfidentialPoliticPage from './pages/confidentialPoliticPage';
import LegalMentionPage from './pages/legalMentionPage';

export default function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="*" element={<NotFound />} />
                    <Route path="/" element={<MainPage />} />
                    <Route path="/spaces" element={<SpacesPage />} />
                    <Route path="/info" element={<InfoPage />} />
                    <Route path="/intervenants" element={<GuestPage />} />
                    <Route path="/conditions" element={<ConditionPage />} />
                    <Route path="/politique-confidentialite" element={<ConfidentialPoliticPage />} />
                    <Route path="/mentions-legales" element={<LegalMentionPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                </Routes>
            </Router>
        </div>
    );
}
