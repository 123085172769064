import React from 'react';

import Header from '../components/header_component.jsx';
import Footer from '../components/footer_component.jsx';

import portraitSabrina from '../images/sabrinaPicture.png';
import portraitNathalie from '../images/portraitNathalie.jpg';

export default function GuestPage() {
    return (
        <>
            <Header />
            <main className="min-h-screen">
                <div className="py-4 flex justify-center">
                    <div
                        className="block align-center p-6 max-w-sm rounded-lg border-[3px]
                    border-gray-200 shadow-md"
                    >
                        <img
                            alt="portrait Nathalie"
                            className="left-0 top-0 w-[50%] mx-auto object-cover object-center mb-2"
                            src={portraitNathalie}
                        />
                        <h1 className="text-sm line-clamp-1">Nathalie POLYMEROS</h1>
                        <p className="text-sm text-gray-500 mt-1 line-clamp-2">
                            Thérapeute holistique, spécialisation enfants et adolescents
                        </p>

                        <span className="flex items-center mx-auto justify-start text-gray-500">
                            <svg
                                className="w-4 h-4"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            <a
                                className="mx-auto"
                                href="https://www.nathalie-polymeros.fr/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://www.nathalie-polymeros.fr/
                            </a>
                        </span>
                    </div>
                </div>
                <div className="py-4 flex justify-center">
                    <div
                        className="block align-center p-6 max-w-sm rounded-lg border-[3px]
                    border-gray-200 shadow-md"
                    >
                        <img
                            alt="portrait Nathalie"
                            className="left-0 top-0 w-[50%] mx-auto object-cover object-center mb-2"
                            src={portraitSabrina}
                        />
                        <h1 className="text-sm line-clamp-1">Sabrina PARENT</h1>
                        <p className="text-sm text-gray-500 mt-1 line-clamp-2">Hypnologue et PNListe Certifiée </p>

                        <span className="flex items-center mx-auto justify-start text-gray-500">
                            <svg
                                className="w-4 h-4"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            <a
                                className="mx-auto"
                                href="https://www.hypnose-savoie.fr/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://www.hypnose-savoie.fr/
                            </a>
                        </span>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}
