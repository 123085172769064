import React from 'react';

import Header from '../components/header_component';
import Footer from '../components/footer_component';

import portraitGilles from '../images/gillesPicture.png';
import portraitSabrina from '../images/sabrinaPicture.png';

export default function InfoPage() {
    return (
        <>
            <Header />
            <main className="min-h-screen">
                <section className="relative block bg-gradient-to-r from-[#E3D8C7] via-rose-50 to-rose-100">
                    <div className="container mx-auto px-4 lg:pt-16 lg:pb-64">
                        <div className="flex flex-wrap text-center justify-center">
                            <div className="w-full lg:w-6/12 px-4">
                                <h2 className="text-2xl font-semibold text-[#7F3E98]">Qui sommes nous ?</h2>
                                <p className="text-lg leading-relaxed mt-4 mb-4 text-[#7F3E98]">
                                    Ce projet est né de la rencontre entre Gilles Rubod, propriétaire et chef
                                    d'entreprise, et Sabrina Parent, consultante en thérapies alternatives.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-wrap mt-12 justify-center">
                            <div className="w-full lg:w-3/12 px-4 text-center">
                                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-[#E94726] inline-flex items-center justify-center">
                                    <i className="fas fa-medal text-xl"></i>
                                </div>
                                <h6 className="text-xl mt-5 font-semibold text-[#E94726]">Espace</h6>
                                <p className="mt-2 mb-4 text-[#7F3E98]">
                                    Ayant accès à une grande superficie de locaux inexploités, Gilles a décidé de les
                                    transformer en plusieurs espaces pour faciliter l'exercice des thérapeutes
                                    alternatifs, formateurs et intervenants.
                                </p>
                            </div>
                            <div className="w-full lg:w-3/12 px-4 text-center">
                                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-[#DF3274] inline-flex items-center justify-center">
                                    <i className="fas fa-poll text-xl"></i>
                                </div>
                                <h5 className="text-xl mt-5 font-semibold text-[#DF3274]">Concept</h5>
                                <p className="mt-2 mb-4 text-[#7F3E98]">
                                    Le système de location de ces espaces à la carte pour à pour but d'aider d'autres
                                    entrepreneurs et favoriser la collaboration.
                                </p>
                            </div>
                            <div className="w-full lg:w-3/12 px-4 text-center">
                                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-[#EC7A21] inline-flex items-center justify-center">
                                    <i className="fas fa-lightbulb text-xl"></i>
                                </div>
                                <h5 className="text-xl mt-5 font-semibold text-[#EC7A21]">Lancement</h5>
                                <p className="mt-2 mb-4 text-[#7F3E98]">
                                    L'objectif final est de permettre à ces professionnels de prospérer en leur offrant
                                    un lieu de pratique flexible et abordable.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="relative py-14">
                    <div
                        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
                        style={{ height: '80px' }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon className="text-white fill-current" points="2560 0 2560 100 0 100"></polygon>
                        </svg>
                    </div>

                    <div className="container mx-auto px-4">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                                <img alt="..." className="max-w-full rounded-lg shadow-lg" src={portraitGilles} />
                                <h3 className="mt-4 text-3xl font-semibold">GILLES</h3>
                                <p className="mt-4 text-lg leading-relaxed text-gray-600">
                                    Chef d’entreprise – ALGITEL systèmes d’alarmes Propriétaire des locaux et fondateur
                                    du projet. <br />
                                    Il gère la partie technique et valide l’aspect financier.
                                </p>
                                <div className="mt-2 mb-5">
                                    <a
                                        href="https://algitel.fr/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="inline-block text-sm px-4 py-2 leading-none border rounded font-bold text-[#7F3E98] border-[#7F3E98] hover:border-transparent hover:bg-[#EC7A21] mt-4 py-1 px-3 rounded lg:mt-0"
                                    >
                                        Site Web
                                    </a>
                                </div>
                            </div>
                            <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                                <img alt="..." className="max-w-full rounded-lg shadow-lg" src={portraitSabrina} />
                                <h3 className="mt-4 text-3xl font-semibold">SABRINA</h3>
                                <p className="mt-4 text-lg leading-relaxed text-gray-600">
                                    Hypnologue – Neuro Hypnose Process <br />
                                    Formatrice / Intervenante / Coach PNL et Gestion des États internes – NHPNL
                                    Trans-Formation <br />
                                    Co-fondatrice et conceptrice du projet.
                                </p>
                                <div className="mt-2 mb-5">
                                    <a
                                        href="https://www.hypnose-savoie.fr/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="inline-block text-sm px-4 py-2 leading-none border rounded font-bold text-[#7F3E98] border-[#7F3E98] hover:border-transparent hover:bg-[#EC7A21] mt-4 py-1 px-3 rounded lg:mt-0"
                                    >
                                        Site Web
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
