import React, { useState } from 'react';
import Header from '../components/header_component';
import Footer from '../components/footer_component';
import Carousel from '../components/carousel_component';

//import images Espace Gaia
import gaia1 from '../images/gaia/gaia1.jpeg';
import gaia2 from '../images/gaia/gaia2.jpeg';
import gaia3 from '../images/gaia/gaia3.jpeg';
import gaia4 from '../images/gaia/gaia4.jpeg';
import gaia5 from '../images/gaia/gaia5.jpeg';

//import images Espace Pléiades
import pleiades1 from '../images/pleiades/pleiades1.jpeg';
import pleiades2 from '../images/pleiades/pleiades2.jpeg';
import pleiades3 from '../images/pleiades/pleiades3.jpeg';
import pleiades4 from '../images/pleiades/pleiades4.jpeg';
import pleiades5 from '../images/pleiades/pleiades5.jpeg';

//import images Espace Nikola Tesla
import tesla1 from '../images/tesla/tesla1.jpg';

const dataGaia = [gaia1, gaia2, gaia3, gaia4, gaia5];
const dataPleiades = [pleiades1, pleiades2, pleiades3, pleiades4, pleiades5];
const dataTesla = [tesla1];

export default function SpacesPage() {
    const [currentSpace, setCurrentSpace] = useState(1);
    return (
        <>
            <Header />
            <main className="min-h-screen">
                <div className="mt-5 mb-10">
                    <h1 className="mt-8 mb-8 font-semibold text-xl tracking-tight text-[#7F3E98]">NOS ESPACES :</h1>
                    <button
                        onClick={(_) => {
                            setCurrentSpace(1);
                        }}
                        className="mr-24 inline-block text-sm px-4 py-2 leading-none border rounded font-bold text-[#E94726] border-[#E94726] hover:bg-rose-100 mt-4 py-1 px-3 rounded lg:mt-0"
                    >
                        Espace Gaïa
                    </button>
                    <button
                        onClick={(_) => {
                            setCurrentSpace(2);
                        }}
                        className="mr-24 inline-block text-sm px-4 py-2 leading-none border rounded font-bold text-[#E94726] border-[#E94726] hover:bg-rose-100 mt-4 py-1 px-3 rounded lg:mt-0"
                    >
                        Espace Les Pléïades
                    </button>
                    <button
                        onClick={(_) => {
                            setCurrentSpace(3);
                        }}
                        className="inline-block text-sm px-4 py-2 leading-none border rounded font-bold text-[#E94726] border-[#E94726] hover:bg-rose-100 mt-4 py-1 px-3 rounded lg:mt-0"
                    >
                        Espace Nikola Tesla
                    </button>
                </div>
                {currentSpace === 1 && (
                    <>
                        <h2 className="mb-5 text-lg font-semibold text-[#7F3E98]">L'Espace Gaïa:</h2>

                        <div className="flex items-center justify-center px-16 mb-28">
                            <div className="relative w-full max-w-lg">
                                <div className="absolute top-0 -left-4 w-72 h-72 bg-[#7F3E98] rounded-full mix-blend-multiply filter blur-xl opacity-40 animate-blob "></div>
                                <div className="absolute top-0 -right-4 w-72 h-72 bg-[#E94726] rounded-full mix-blend-multiply filter blur-xl opacity-40 animate-blob animation-delay-2000"></div>
                                <div className="absolute -bottom-32 left-20 w-72 h-72 bg-[#EC7A21] rounded-full mix-blend-multiply filter blur-xl opacity-40 animate-blob animation-delay-4000"></div>

                                <div className="m-8 relative space-y-6">
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">
                                                • 40m² dédiés à la pratique de thérapies
                                            </p>
                                        </div>
                                    </div>
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">
                                                • Accueil de groupes (8 personnes max.)
                                            </p>
                                        </div>
                                    </div>
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">
                                                • Une table de massage avec trou,
                                            </p>
                                        </div>
                                    </div>
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">
                                                • Une petite armoire de rangement
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative w-full max-w-lg">
                                <div className="absolute top-0 -left-4 w-72 h-72 bg-[#7F3E98] rounded-full mix-blend-multiply filter blur-xl opacity-40 animate-blob "></div>
                                <div className="absolute top-0 -right-4 w-72 h-72 bg-[#E94726] rounded-full mix-blend-multiply filter blur-xl opacity-40 animate-blob animation-delay-2000"></div>
                                <div className="absolute -bottom-32 left-20 w-72 h-72 bg-[#EC7A21] rounded-full mix-blend-multiply filter blur-xl opacity-40 animate-blob animation-delay-4000"></div>
                                <div className="m-8 relative space-y-6">
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-6">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">
                                                • Espace commun avec : <br />
                                                évier, théïère, cafetière, micro-onde.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg items-center space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">
                                                • Espace sanitaire non-attenant
                                            </p>
                                        </div>
                                    </div>
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">• Un vestiaire</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 className="mb-5 text-lg font-semibold text-[#7F3E98]">L'Espace Gaïa en images :</h3>
                        <Carousel data={dataGaia} />
                        <div>
                            <a
                                href="https://prendreunrendezvous.fr/rendez-vous/389249/location/julea-sci-espace-carpediem"
                                target="_blank"
                                rel="noreferrer"
                                className="inline-block text-sm px-4 py-2 leading-none border rounded font-bold text-[#C2B8AA] border-[#C2B8AA] hover:border-transparent hover:bg-[#7F3E98] mt-4 mb-4 py-1 px-3 rounded lg:mt-0"
                            >
                                Réserver
                            </a>
                        </div>
                    </>
                )}
                {currentSpace === 2 && (
                    <>
                        <h2 className="mb-5 text-lg font-semibold text-[#7F3E98]">L'Espace Les Pléïades:</h2>

                        <div className="flex items-center justify-center px-16 mb-28">
                            <div className="relative w-full max-w-lg">
                                <div className="absolute top-0 -left-4 w-72 h-72 bg-[#7F3E98] rounded-full mix-blend-multiply filter blur-xl opacity-40 animate-blob "></div>
                                <div className="absolute top-0 -right-4 w-72 h-72 bg-[#E94726] rounded-full mix-blend-multiply filter blur-xl opacity-40 animate-blob animation-delay-2000"></div>
                                <div className="absolute -bottom-32 left-20 w-72 h-72 bg-[#EC7A21] rounded-full mix-blend-multiply filter blur-xl opacity-40 animate-blob animation-delay-4000"></div>

                                <div className="m-8 relative space-y-6">
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">
                                                • 50m² dédiés à la pratique de thérapies
                                            </p>
                                        </div>
                                    </div>
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">
                                                • Accueil de groupes (15 personnes max.)
                                            </p>
                                        </div>
                                    </div>
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">
                                                • Un bureau de consultation avec chaises
                                            </p>
                                        </div>
                                    </div>
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">
                                                • Une table de massage (avec trou)
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative w-full max-w-lg">
                                <div className="absolute top-0 -left-4 w-72 h-72 bg-[#7F3E98] rounded-full mix-blend-multiply filter blur-xl opacity-40 animate-blob "></div>
                                <div className="absolute top-0 -right-4 w-72 h-72 bg-[#E94726] rounded-full mix-blend-multiply filter blur-xl opacity-40 animate-blob animation-delay-2000"></div>
                                <div className="absolute -bottom-32 left-20 w-72 h-72 bg-[#EC7A21] rounded-full mix-blend-multiply filter blur-xl opacity-40 animate-blob animation-delay-4000"></div>
                                <div className="m-8 relative space-y-6">
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-6">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">
                                                • Un fauteuil, un canapé, un relax
                                            </p>
                                        </div>
                                    </div>
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg items-center space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">
                                                • Une petite armoire de rangement
                                            </p>
                                        </div>
                                    </div>
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">
                                                • Une kitchenette, un vestiaire
                                            </p>
                                        </div>
                                    </div>
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">• Un espace sanitaire</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 className="mb-5 text-lg font-semibold text-[#7F3E98]">L'Espace Les Pléiades en images :</h3>
                        <Carousel data={dataPleiades} />
                        <div>
                            <a
                                href="https://prendreunrendezvous.fr/rendez-vous/389249/location/julea-sci-espace-carpediem"
                                target="_blank"
                                rel="noreferrer"
                                className="inline-block text-sm px-4 py-2 leading-none border rounded font-bold text-[#C2B8AA] border-[#C2B8AA] hover:border-transparent hover:bg-[#7F3E98] mt-4 mb-4 py-1 px-3 rounded lg:mt-0"
                            >
                                Réserver
                            </a>
                        </div>
                    </>
                )}
                {currentSpace === 3 && (
                    <>
                        <h2 className="mb-5 text-lg font-semibold text-[#7F3E98]">L'Espace Nikola Tesla:</h2>

                        <div className="flex items-center justify-center px-16 mb-28">
                            <div className="relative w-full max-w-lg">
                                <div className="absolute top-0 -left-4 w-72 h-72 bg-[#7F3E98] rounded-full mix-blend-multiply filter blur-xl opacity-40 animate-blob "></div>
                                <div className="absolute top-0 -right-4 w-72 h-72 bg-[#E94726] rounded-full mix-blend-multiply filter blur-xl opacity-40 animate-blob animation-delay-2000"></div>
                                <div className="absolute -bottom-32 left-20 w-72 h-72 bg-[#EC7A21] rounded-full mix-blend-multiply filter blur-xl opacity-40 animate-blob animation-delay-4000"></div>

                                <div className="m-8 relative space-y-6">
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">
                                                • Une grande dédiée à l’exercice de conférences, interventions et
                                                formations
                                            </p>
                                        </div>
                                    </div>
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">
                                                • Accueil de groupes (15 personnes max.)
                                            </p>
                                        </div>
                                    </div>
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">
                                                • Un espace détente pour les pauses
                                            </p>
                                        </div>
                                    </div>
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">• Une caféteria</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative w-full max-w-lg">
                                <div className="absolute top-0 -left-4 w-72 h-72 bg-[#7F3E98] rounded-full mix-blend-multiply filter blur-xl opacity-40 animate-blob "></div>
                                <div className="absolute top-0 -right-4 w-72 h-72 bg-[#E94726] rounded-full mix-blend-multiply filter blur-xl opacity-40 animate-blob animation-delay-2000"></div>
                                <div className="absolute -bottom-32 left-20 w-72 h-72 bg-[#EC7A21] rounded-full mix-blend-multiply filter blur-xl opacity-40 animate-blob animation-delay-4000"></div>
                                <div className="m-8 relative space-y-6">
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-6">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">• Un réfrigérateur</p>
                                        </div>
                                    </div>
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg items-center space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">• Des sanitaires</p>
                                        </div>
                                    </div>
                                    <div className="p-5 bg-rose-50 border-[2px] border-rose-200 rounded-lg flex items-center justify-between space-x-8">
                                        <div className="flex-1 flex items-center">
                                            <div className="w-16 h-6 rounded-lg bg-purple-300 mr-3"></div>
                                            <p className="font-semibold text-[#7F3E98]">• Un vidéoprojecteur</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 className="mb-5 text-lg font-semibold text-[#7F3E98]">Encore un peu de patience :</h3>
                        <Carousel data={dataTesla} />
                    </>
                )}
            </main>
            <Footer />
        </>
    );
}
