import React from 'react';

import Header from '../components/header_component';
import Footer from '../components/footer_component';

import bgContact from '../images/facade.jpg';

import { HiOutlineMail, HiPhone, HiOutlineMap } from 'react-icons/hi';
import { FaFacebookSquare } from 'react-icons/fa';

export default function ContactPage() {
    return (
        <>
            <Header />
            <main className="min-h-screen">
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center bg-purple-100">
                    <div>
                        <h2 className="text-2xl font-semibold text-[#7F3E98]">Contactez-nous !</h2>
                        <p className="text-xl font-semibold text-[#7F3E98] mb-10">
                            Vous pouvez nous contacter par mail ou par téléphone.
                        </p>
                        <img alt=" " src={bgContact} className="rounded-3xl" />
                    </div>

                    <div className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-16">
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon className="text-gray-50 fill-current" points="2560 0 2560 100 0 100"></polygon>
                        </svg>
                    </div>
                </div>
                <section className="pb-20 bg-gray-50">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap">
                            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center mt-20">
                                <div className="relative flex flex-col min-w-0 break-words bg-[#7F3E98] bg-opacity-25 w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                            <HiOutlineMail size={25} />
                                        </div>
                                        <h6 className="text-xl font-semibold">Email</h6>
                                        <p className="mt-2 mb-4 text-gray-600">
                                            <a href="mailto:carpediem.yenne@gmail.com" target="_blank" rel="noreferrer">
                                                carpediem.yenne@gmail.com
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full md:w-4/12 px-4 text-center mt-20">
                                <div className="relative flex flex-col min-w-0 break-words bg-[#7F3E98] bg-opacity-25 w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                            <HiPhone size={25} />
                                        </div>
                                        <h6 className="text-xl font-semibold">Téléphone</h6>
                                        <p className="mt-2 mb-4 text-gray-600">
                                            <a href="tel:0479363226" target="_blank" rel="noreferrer">
                                                04.79.36.92.26
                                            </a>{' '}
                                            <br />
                                            <a href="tel:0651676193" target="_blank" rel="noreferrer">
                                                06.51.67.61.93
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-6 w-full md:w-4/12 px-4 text-center mt-20">
                                <div className="relative flex flex-col min-w-0 break-words bg-[#7F3E98] bg-opacity-25 w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                            <HiOutlineMap size={25} />
                                        </div>
                                        <h6 className="text-xl font-semibold">Adresse</h6>
                                        <p className="mt-2 mb-4 text-gray-600">
                                            ZAC des Fontanettes <br />
                                            73170, YENNE, FRANCE
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center"></div>

                            <div className="w-full md:w-4/12 px-4 text-center mt-2">
                                <div className="relative flex flex-col min-w-0 break-words bg-[#7F3E98] bg-opacity-25 w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                            <a
                                                href="https://www.facebook.com/people/Carpediem-espace-bien-être/100064023455929/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <FaFacebookSquare size={25} />
                                            </a>
                                        </div>
                                        <h6 className="text-xl font-semibold">
                                            <a
                                                href="https://www.facebook.com/people/Carpediem-espace-bien-être/100064023455929/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Facebook
                                            </a>
                                        </h6>
                                        <p className="mt-2 mb-4 text-gray-600">
                                            <a
                                                href="https://www.facebook.com/people/Carpediem-espace-bien-être/100064023455929/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Suivez-nous !
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="relative pt-10 pb-20 bg-purple-100">
                    <div
                        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
                        style={{ height: '80px' }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon className="text-purple-100 fill-current" points="2560 0 2560 100 0 100"></polygon>
                        </svg>
                    </div>
                    <h2 className="text-2xl font-semibold text-black pb-5">Nous sommes ici !</h2>
                    <div className="mx-10">
                        <iframe
                            title="map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2786.2768596678793!2d5.759649051915101!3d45.70548237900211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478b0db6d8e5d26b%3A0xdd3a974b6939201a!2sALGITEL!5e0!3m2!1sfr!2sfr!4v1678185163210!5m2!1sfr!2sfr"
                            height="700"
                            width="100%"
                            allowFullScreen
                        ></iframe>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
