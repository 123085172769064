import React from 'react';
import logoOutline from '../images/logoOutlineColor.png';
import { TiInfoLargeOutline } from 'react-icons/ti';
import { TbZoomMoney, TbPhone } from 'react-icons/tb';

export default function Footer() {
    return (
        <>
            <div className="w-full flex items-center justify-center bg-gradient-to-r from-rose-50 via-slate-100 to-rose-50 border-t-[3px] border-t-rose-100">
                <div className="md:w-2/3 w-full px-4 text-[#E94726] flex flex-col">
                    <div className="flex flex-col">
                        <div className="flex mt-14 justify-between">
                            <div className="">
                                <img src={logoOutline} alt="Logo Carpediem" className="h-44"></img>
                            </div>
                            <a
                                href="/info"
                                className="hidden md:flex items-center cursor-pointer text-[#7F3E98] hover:text-[#E94726] uppercase"
                            >
                                A propos
                                <TiInfoLargeOutline size={30} className="text-[#7F3E98] ml-2" />
                            </a>

                            <a
                                href="/conditions"
                                className="hidden md:flex items-center cursor-pointer text-[#7F3E98] hover:text-[#E94726] uppercase"
                            >
                                Conditions & Tarifs
                                <TbZoomMoney size={30} className="text-[#7F3E98] ml-2" />
                            </a>
                            <a
                                href="/contact"
                                className="hidden md:flex items-center cursor-pointer text-[#7F3E98] hover:text-[#E94726] uppercase"
                            >
                                Contact
                                <TbPhone size={30} className="text-[#7F3E98] ml-2" />
                            </a>
                            <div className="flex flex-row space-x-8 items-center justify-between">
                                <a
                                    href="https://www.facebook.com/people/Carpediem-espace-bien-%C3%AAtre/100064023455929/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <svg
                                        width="12"
                                        height="24"
                                        viewBox="0 0 6 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3.89782 12V6.53514H5.67481L5.93895 4.39547H3.89782V3.03259C3.89782 2.41516 4.06363 1.99243 4.91774 1.99243H6V0.0847928C5.47342 0.0262443 4.94412 -0.00202566 4.41453 0.000112795C2.84383 0.000112795 1.76542 0.994936 1.76542 2.82122V4.39147H0V6.53114H1.76928V12H3.89782Z"
                                            fill="#7F3E98"
                                        />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <hr className="border-[#7F3E98]" />
                        <div className="flex justify-between mt-2">
                            <a
                                href="/politique-confidentialite"
                                className="w-full text-center text-[#E94726] hover:text-[#7F3E98] hover:underline"
                            >
                                Politique de confidentialité
                            </a>
                            <a
                                href="/mentions-legales"
                                className="w-full text-center text-[#E94726] hover:text-[#7F3E98] hover:underline"
                            >
                                Mentions légales
                            </a>
                        </div>
                        <p className="w-full text-center my-12 text-[#7F3E98]">Copyright © 2023 Carpediem</p>
                    </div>
                </div>
            </div>
        </>
    );
}
