import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
    const navigate = useNavigate();

    function handleBackHome() {
        navigate('/');
    }

    return (
        <>
            <main>
                <div className="h-screen w-full flex flex-col justify-center items-center bg-indigo-900">
                    <h1 className="text-9xl font-extrabold text-white tracking-widest">404</h1>
                    <p className="text-white">
                        <span className="text-purple-300">Oups ! </span>La page que vous recherchez semble introuvable.
                    </p>
                    <div className="bg-purple-300 px-2 text-sm rounded rotate-12 absolute">Page introuvable</div>
                    <button className="mt-5">
                        <div className="relative inline-block text-sm font-medium text-purple-300 group active:text-purple-500 focus:outline-none focus:ring">
                            <span className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-purple-300 group-hover:translate-y-0 group-hover:translate-x-0"></span>
                            <span className="relative block px-8 py-3 bg-indigo-900 border border-current">
                                <button onClick={handleBackHome}>Retour à l'accueil</button>
                            </span>
                        </div>
                    </button>
                </div>
            </main>
        </>
    );
}
