import React from 'react';
import Header from '../components/header_component';
import Footer from '../components/footer_component';

import metiersBienEtre from '../images/metiersBienEtre.jpeg';
import nature from '../images/nature.jpg';

import { AiOutlineTeam, AiOutlineQuestion, AiOutlineSafetyCertificate } from 'react-icons/ai';
import { TfiAgenda, TfiKey, TfiMoney } from 'react-icons/tfi';
import { BiTimeFive } from 'react-icons/bi';

export default function ConditionPage() {
    return (
        <>
            <Header />
            <main className="min-h-screen">
                <section className="pb-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-2xl font-semibold text-[#7F3E98] pt-10">Réservation et conditions</h2>
                        <p className="text-xl mt-10 text-neutral-600">
                            Le concept de Carpe Diem est une option de location flexible qui permet aux thérapeutes et
                            intervenants de s'épanouir dans leur profession tout en répondant à leurs besoins
                            financiers.
                            <br />
                            Vous pouvez réserver votre espace pour une journée ou une demi-journée, selon vos besoins de
                            rendez-vous avec vos patients ou clients. <br />
                            L'objectif de Carpe Diem est de vous aider à faire prospérer votre activité en offrant des
                            options de location fixes et/ou définitives via un contrat évolutif.
                        </p>

                        <div className="flex flex-wrap items-center mt-10">
                            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-[#7F3E98]">
                                    <AiOutlineTeam size={30} className="text-white" />
                                </div>
                                <h3 className="text-3xl mb-2 font-semibold leading-normal">Pour qui ?</h3>
                                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                                    Carpe Diem est un espace ouvert à tous les thérapeutes alternatifs, conférenciers,
                                    formateurs et intervenants. À long terme, l'objectif est d'accueillir un thérapeute
                                    pour chaque domaine.
                                </p>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700"></p>
                            </div>

                            <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6">
                                    <img alt="..." src={metiersBienEtre} className="w-full align-middle rounded-full" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="relative py-20">
                    <div
                        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
                        style={{ height: '80px' }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon className="text-white fill-current" points="2560 0 2560 100 0 100"></polygon>
                        </svg>
                    </div>

                    <div className="container mx-auto px-4 mb-5">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                                <div className="md:pr-12">
                                    <div className="mr-5 text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-300">
                                        <TfiMoney size={30} className="text-black" />
                                    </div>
                                    <div className="ml-5 text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-300">
                                        <BiTimeFive size={30} className="text-black" />
                                    </div>

                                    <h3 className="text-3xl font-semibold mb-3">Nos Tarifs :</h3>
                                    <ul>
                                        <li>Location à la demi-journée (4h) : 20,00 €</li>
                                        <li>Location à la journée (8h) : 36,00 € </li>
                                    </ul>
                                    <h3 className="text-3xl font-semibold mt-5">Horaires des réservations :</h3>
                                    <h4 className="text-xl font-semibold underline mt-2 mb-3">
                                        1/2 journée (4 heures)
                                    </h4>
                                    <ul>
                                        <li>8h00 – 12h00</li>
                                        <li>13h00 – 17h00</li>
                                        <li>18h00 – 22h00</li>
                                    </ul>
                                    <h4 className="text-xl font-semibold underline mt-2 mb-3"> 1 journée (8 heures)</h4>
                                    <ul>
                                        <li>Trois demi-journées au choix</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="relative py-20 bg-[#EC7A21] bg-opacity-25">
                    <div
                        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
                        style={{ height: '80px' }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-[#EC7A21] text-opacity-25 fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>

                    <div className="container mx-auto px-4">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                                <img alt="..." className="max-w-full rounded-lg shadow-lg" src={nature} />
                            </div>
                            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                                <div className="md:pr-12">
                                    <div className="p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-600">
                                        <AiOutlineQuestion size={35} className="text-[#EC7A21]" />
                                    </div>
                                    <h3 className="text-3xl font-semibold">Comment ?</h3>
                                    <p className="mt-4 text-lg leading-relaxed text-gray-600">
                                        Depuis ce site, vous pouvez réserver un espace en un clic. <br />
                                        Carpe Diem dispose de trois espaces :
                                    </p>
                                    <ul className="list-none mt-6">
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <h4 className="text-gray-600">
                                                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-gray-600 mr-3"></span>
                                                    Deux espaces pour la pratique de thérapies alternatives et pour
                                                    accueillir de petits groupes (maximum 15 personnes).
                                                </h4>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <h4 className="text-gray-600">
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-gray-600 mr-3"></span>
                                                Par la suite, un espace dédié aux conférences, interventions et
                                                formations (maximum 50 personnes) sera mis à disposition.
                                            </h4>
                                        </li>
                                    </ul>
                                    <p className="mt-4 text-lg leading-relaxed text-gray-600">
                                        Tous les espaces sont équipés de matériaux professionnels pour vous permettre de
                                        pratiquer en toute sérénité.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap mt-16">
                        <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                            <div className="relative flex flex-col min-w-0 break-words bg-transparent w-full mb-8 rounded-lg">
                                <div className="px-4 py-5 flex-auto">
                                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-24 h-24 mb-5 shadow-lg rounded-full bg-red-400">
                                        <TfiAgenda size={40} className="text-white" />
                                    </div>
                                    <h6 className="text-xl font-semibold">Étape 1</h6>
                                    <div className="mt-2 mb-4 text-gray-600">
                                        <p>Réserver votre créneau sur l'agenda et payer en ligne.</p>
                                        <br />
                                        <div className="mt-3">
                                            <a
                                                href="https://prendreunrendezvous.fr/rendez-vous/389249/location/julea-sci-espace-carpediem"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="inline-block text-sm px-4 py-2 leading-none border rounded font-bold text-red-400 border-red-400 hover:border-transparent hover:bg-[#7F3E98] mt-4 py-1 px-3 rounded lg:mt-0"
                                            >
                                                Réserver
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full md:w-4/12 px-4 text-center">
                            <div className="relative flex flex-col min-w-0 break-words bg-transparent w-full mb-8 rounded-lg">
                                <div className="px-4 py-5 flex-auto">
                                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-24 h-24 mb-5 shadow-lg rounded-full bg-[#EC7A21]">
                                        <TfiKey size={50} className="text-white" />
                                    </div>
                                    <h6 className="text-xl font-semibold">Étape 2</h6>
                                    <p className="mt-2 mb-4 text-gray-600">
                                        Validation de la réservation, visite des locaux <br />
                                        Remise du badge d'accès
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                            <div className="relative flex flex-col min-w-0 break-words bg-transparent w-full mb-8 rounded-lg">
                                <div className="px-4 py-5 flex-auto">
                                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-24 h-24 mb-5 shadow-lg rounded-full bg-[#7F3E98]">
                                        <AiOutlineSafetyCertificate size={50} className="text-red" />
                                    </div>
                                    <h6 className="text-xl font-semibold">Étape 3</h6>
                                    <p className="mt-2 mb-4 text-gray-600">
                                        Parfait ! <br />
                                        Vous pouvez désormais accéder à votre espace de travail.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    );
}
