import React from 'react';

import Header from '../components/header_component';
import Footer from '../components/footer_component';

export default function LegalMentionPage() {
    return (
        <>
            <Header />
            <main className="min-h-screen">
                <div className="text-left pl-32 py-5">
                    <h1 className="font-semibold underline">MENTIONS LÉGALES</h1>
                    <br />
                    <p>
                        Conformément à l’article 6 de la loi du 21 juin 2004, pour la confiance dans l’économie
                        numérique, nous vous informons que ce service de communication au public en ligne est édité par
                        :
                    </p>
                    <br />
                    <p>
                        la société SCI JULEA
                        <br />
                        située ZAC des Fontannettes 73170 YENNE
                    </p>
                    <br />
                    <p>RCS : 534343835 CHAMBERY </p>
                    <br />
                    <p>
                        Le directeur de la publication est M. RUBOD
                        <br />
                        Le prestataire mentionné au 2 du I de l’article 6 de la loi précitée, est :
                    </p>
                    <br />
                    <p>
                        OVH (OVH Group SA) <br />
                        2 rue Kellermann <br />
                        59100 Roubaix - France
                    </p>
                    <br />
                    <p>
                        Aux termes de l’article L 122-4 du Code de la Propriété Intellectuelle : Toute représentation ou
                        reproduction intégrale ou partielle faite sans le consentement de l’auteur ou de ses ayants
                        droit ou ayants cause est illicite. Il en est de même pour la traduction, l’adaptation ou la
                        transformation, l’arrangement ou la reproduction par un art ou un procédé quelconque.
                    </p>
                    <br />
                    <p>
                        L’ensemble des éléments composant ce site (noms, logos, textes, graphismes, photographies,
                        images, nom de domaine, créations et œuvres protégées diverses, etc…) sont la propriété
                        exclusive de la SCI JULEA et sont sujets à la législation françaises et internationales sur la
                        propriété intellectuelle. Seule une utilisation à des fins strictement personnelles est
                        autorisée.
                    </p>
                    <br />
                    <p>
                        La reproduction de tout ou partie de ce site sur quelque support que ce soit est formellement
                        interdite sauf autorisation expresse de M. RUBOD.
                    </p>
                    <br />
                    <p>
                        Malgré tous les soins apportés à la réalisation de ce site et à son actualisation régulière, des
                        erreurs peuvent s’être glissées dans les informations et/ou documents présentés. Les
                        utilisateurs du site procéderont donc à toutes vérifications utiles.
                    </p>
                </div>
            </main>
            <Footer />
        </>
    );
}
